<template>
  <div>
    <b-table
      striped
      hover
      responsive
      small
      show-empty
      class="position-relative items-center"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #empty="scope">
        <div class="p-2 text-center">
          <img
            src="/box.png"
            alt="empty"
            height="40px"
          >
          {{
            scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
          }}
        </div>
      </template>
      <template #cell(detail)="data">
        <div class="text-nowrap">
          <b-button
            title="รายละเอียด"
            variant="gradient-info"
            size="sm"
            @click="
              $router.push({
                name: 'Deposit-Withdraw',
                params: { id: data.item.username },
              })
            "
          >
            <i class="fas fa-address-card" />
          </b-button>
        </div>
      </template>
      <template #cell(main_wallet)="data">
        <span class="text-warning">{{
          data.item.main_wallet.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}</span>
      </template>
      <template #cell(depfirst_amount)="data">
        <span class="text-success">{{
          data.item.depfirst_amount.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        }}</span>
      </template>
      <template #cell(total_deposit)="data">
        <span class="text-success">{{
          data.item.total_deposit.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        }}</span>
      </template>
      <template #cell(withdraw)="data">
        <span class="text-danger">{{
          data.item.withdraw.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        }}</span>
      </template>
      <template #cell(bonus)="data">
        <span class="text-primary">{{
          data.item.bonus.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}</span>
      </template>
      <template #cell(tel)="data">
        {{ data.item.tel }}
      </template>

      <template #cell(name)="data">
        {{ data.item.name }}
        {{ data.item.surname }}
      </template>

      <template #cell(user_pass)="data">
        <button
          :id="data"
          class="btn btn-sm btn-warning"
          @click="$emit('getCreditBalance', data.item.username)"
        >
          {{ data.item.username }}
        </button>
      </template>

      <template #cell(bankname)="data">
        <div class="text-center">
          <img
            :src="`/bankIcon/${data.item.bank_img}`"
            alt="bankIcon"
            height="25"
            width="25"
            class="rounded-circle"
          >
          <br>
          <small>{{ data.item.acc_no }}</small>
        </div>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          id="dropdown-3"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          text="Success"
          variant="success"
        >
          <template #button-content>
            <i class="far fa-edit mr-50" />
          </template>

          <b-dropdown-item
            @click="banModal(data.item.username)"
          ><i class="far fa-check mr-1 text-success" /> ปิดยอดสมัคร</b-dropdown-item>

          <b-dropdown-item
            @click="
              $router.push({
                name: 'history-turnover',
                params: { id: data.item.username },
              })
            "
          ><i class="far fa-file-spreadsheet mr-1 text-info" /> Turn
            Over</b-dropdown-item>

          <b-dropdown-item
            @click="
              $router.push({
                name: 'Deposit-Withdraw',
                params: { id: data.item.username },
              })
            "
          ><i class="far fa-edit mr-1 text-warning" /> แก้ไข</b-dropdown-item>

          <b-dropdown-item><i class="fas fa-trash mr-1 text-danger" /> ลบ
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      ref="ban-modal"
      title="ปิดยอดสมัคร"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      @ok="banUser"
    >
      <b-form-group label="เลือกแอดมิน">
        <v-select
          v-model="firstdep_byadmin"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="agent.map((item) => ({
            title: item.name,
            value: item.name,
          }))"
          :reduce="value => value.value"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  VBToggle,
  VBTooltip,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    BTable,
    VBToggle,
    VBTooltip,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: ['items', 'perPage'],
  emits: ['sorted', 'getCreditBalance'],
  data: () => ({
    agent: [],
    firstdep_byadmin: null,
    username: null,
    fields: [
      { key: 'detail', label: 'รายละเอียด' },
      { key: 'main_wallet', label: 'ยอดเงิน', sortable: true },
      { key: 'tel', label: 'เบอร์โทร' },
      { key: 'name', label: 'ชื่อ-นามสกุล' },
      { key: 'deposit_count', label: 'ครั้งที่ฝาก', sortable: true },
      { key: 'depfirst_amount', label: 'ฝากแรก', sortable: true },
      {
        key: 'depfirst_date',
        label: 'ฝากแรก',
        sortable: true,
        formatter: value => (value
          ? moment(value).tz('Asia/Bangkok').format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'bonus', label: 'โบนัส' },
      // { key: 'withdraw_count', label: 'ครั้งที่ถอน', sortable: true },
      // { key: 'withdraw', label: 'ถอนรวม', sortable: true },
      { key: 'invited_by_name', label: 'ผู้แนะนำ' },
      { key: 'bankname', label: 'ธนาคาร', thClass: 'text-center' },
      {
        key: 'created_at',
        label: 'สมัครเมื่อ',
        sortable: true,
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .add('hours', 7)
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'user_pass', label: 'User / Password[พนัน]' },
      { key: 'firstdep_byadmin', label: 'ปิดยอดสมัคร' },
      { key: 'actions', label: 'จัดการ' },
    ],
  }),
  mounted() {
    this.getAdmin()
  },
  methods: {
    async getAdmin() {
      try {
        const { data } = await this.$http.get('/agent')
        this.agent = data
      } catch (error) {
        console.log(error)
      }
    },
    banModal(username) {
      this.$refs['ban-modal'].show()
      // console.log(username)
      this.username = username
    },
    async banUser() {
      try {
        // console.log(this.firstdep_byadmin)
        const obj = {
          username: this.username,
          byadmin: this.firstdep_byadmin,
        }
        // eslint-disable-next-line no-unused-vars
        await this.$http.post('/users/fisrtdepbyadmin', obj)
        this.$refs['ban-modal'].hide()
        this.username = null
        this.firstdep_byadmin = null
        this.$emit('sorted')
      } catch (error) {
        console.log(error)
      }
    },
    onSortChanged(ctx) {
      const { sortBy, sortDesc } = ctx

      this.$emit('sorted', { sortBy, sortDesc })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
